<style scoped>
    .fit-fill{
        object-fit: fill;
    }
</style>
<template>
    <div class="bg-black h-100 position-relative">
        <h3 class="position-absolute top-50 start-50 translate-middle text-muted">{{ callTip }}</h3>
        <video autoplay class="d-block w-100 h-100 rounded" :srcObject="peerStream"></video>
        <div class="position-absolute img-thumbnail end-0 top-0 mt-2 me-2 bg-black w-25 h-25">
            <video ref="abc" muted autoplay :class="{'invisible':!isVideo}" class="d-block w-100 h-100 rounded fit-fill" :srcObject="localStream"></video>
            <div v-show="!isVideo" class="position-absolute start-0 end-0 top-0 bottom-0 p-1">
                <div class="d-flex flex-column justify-content-center align-items-center bg-secondary bg-opacity-50 rounded h-100">
                    <i class="bi bi-camera-video-off-fill d-flex display-1"></i>
                    <div>{{videoTip}}</div>
                </div>
            </div>
        </div>
        <div class="position-absolute d-flex justify-content-center fs-2 w-100 bottom-0 mb-4">
            <div v-if="isShowMediaBtn" :class="[isAudio?'border-info':'bg-secondary bg-opacity-50 opacity-50']" class="text-white rounded-circle border border-2 p-2 mx-3" @click="btnOperate(operate.audio)" role="button">
                <i :class="[isAudio?'bi-mic-fill text-info':'bi-mic-mute-fill']" class="bi d-flex"></i>
            </div>
            <div v-if="isShowMediaBtn" :class="[isVideo?'border-info':'bg-secondary bg-opacity-50 opacity-50']" class="text-white rounded-circle border border-2 p-2 mx-3" @click="btnOperate(operate.video)" role="button">
                <i :class="[isVideo?'bi-camera-video-fill text-info':'bi-camera-video-off-fill']" class="bi d-flex"></i>
            </div>
            <div class="text-white rounded-circle border border-2 border-danger p-2 mx-3 bg-danger" @click="btnOperate(operate.close)" role="button">
                <i class="bi bi-telephone-fill d-flex" style="transform:rotateZ(135deg)"></i>
            </div>
        </div>
    </div>
</template>
<script>
//使用WebRTC的点对点服务，安装：npm install peerjs，官网：https://github.com/peers/peerjs/
import { Peer } from "peerjs";
import { LAYER_TYPE } from "@/assets/js/const.js";
import { useLayer } from "@/assets/js/useLayer.js"
    export default {
        data() {
            return {
                isAudio: true,//是否正在语音
                isVideo: false,//是否正在视频
                localStream:null,//本地流
                peerStream:null,//对等端流
                //操作类型
                operate:{
                    video:1,
                    audio:2,
                    close:3
                },
                callTip:"",//呼叫提示文本
                videoTip:"摄像头未开启",//摄像头提示文本
                peerId:this.$route.params["peerId"],//对等方的id
                peer:null,
                isShowMediaBtn:false,//是否显示操作媒体的按钮
                isOpenCallTimer:false,//是否开启间隔呼叫对等方定时器
            };
        },
        created() {
            if(this.peerId){//存在对等方id，说明是呼叫方
                this.callTip="资源加载中...";
            }else{//否则为被呼叫方
                this.callTip="等待对方呼叫";
            }
        },
        unmounted() {
            this.peer&&this.peer.destroy();
        },
        mounted() {
            console.log("正在连接对等服务器");
            try {
                //使用自定义id创建对等方（我）
                // this.peer=new Peer(
                //     this.$route.params["localId"]
                //     // ,
                //     // {
                //     //     config: {
                //     //         // 'iceServers': [
                //     //         //     { url: 'stun:test.testapp.life' },
                //     //         //     { url: 'turnserver:test.testapp.life',username:'turnserver1',password:'password1' }
                //     //         // ]
                //     //         // 'iceServers': [
                //     //         //     { url: 'stun:test.testapp.life' },
                //     //         // ],
                //     //         // 'turnServers':[
                //     //         //     { url: 'turnserver:test.testapp.life',username:'turnserver1',password:'password1' }
                //     //         // ]
                //     //     }
                //     // }
                // );
                this.peer=new Peer(
                    this.$route.params["localId"]
                    ,{
                        host:'139.84.164.93',
                        port:"9100",
                        path:"/peer-server",
                    }
                );
                
            } catch (error) {
                // alert("连接对等服务器失败")
                console.log("连接对等服务器失败")
            }
            
            //连接到对等服务器
            this.peer.on('open',(localId)=>{

                console.log("已连接到对等服务器，我的id是"+localId);

                //获取本地媒体
                this.getUserMedia();
                // this.getUserMedia({
                //     audio:true,
                //     video:true
                // },(localStream)=>{
                //     // alert("成功")
                //     //默认禁用视频轨道
                //     (!this.isVideo)&&(localStream.getVideoTracks()[0].enabled=false);

                //     this.localStream=localStream;
                //     console.log("已使用本地流绑定我的媒体");

                //     //呼叫方
                //     let peerId=this.$route.params["peerId"];//获取被呼叫方id
                //     if(peerId){
                //         //呼叫
                //         // let mediaConnection = this.peer.call(peerId,this.localStream);
                //         let mediaConnection = this.peer.call(peerId,new MediaStream());
                //         console.log("呼叫对方");
                //         //绑定被呼叫方添加流的事件
                //         mediaConnection.on('stream',(stream)=>{
                //             console.log("被呼叫方添加流");
                //             this.peerStream=stream;

                //         })
                //     }

                // })



            })

            //绑定我方被呼叫事件
            this.peer.on("call",(mediaConnection)=>{
                console.log("正在被呼叫");

                //接受呼叫
                mediaConnection.answer(this.localStream);
                console.log("已接听，对方id是"+mediaConnection.peer);
                //绑定呼叫方添加流的事件
                mediaConnection.on('stream',(stream)=>{
                    console.log("呼叫方添加流");
                    this.callTip="";
                    this.peerStream=stream;
                    console.dir(stream.getVideoTracks()[0].enabled)
                })
                mediaConnection.on('close',()=>{
                    console.log("任意对等方关闭媒体连接事件");
                })
                mediaConnection.on('error',()=>{
                    console.log("媒体连接错误事件");
                })
                
            })

            this.peer.on("connection",(conn)=>{
                console.dir("conn"+conn);
            })

            this.peer.on("close",()=>{
                console.log("对等端被销毁并且无法再接受或创建任何新连接时发出");
            })

            this.peer.on("disconnected",()=>{
                console.log("当对等方手动或由于与信令服务器的连接丢失而与信令服务器断开连接时发出。当对等方断开连接时，其现有连接将保持活动状态，但该对等方不能接受或创建任何新连接。您可以通过调用peer.reconnect（）重新连接到服务器。");
            })

            this.peer.on("error",(err)=>{
                console.log("对等机上的错误几乎总是致命的，并且会破坏对等机。此处转发来自底层套接字和对等连接的错误。");
                switch (err.type) {
                    case 'browser-incompatible':
                        useLayer({str:"您的浏览器暂不支持使用该功能！"});
                        break;
                    case 'disconnected':
                        useLayer({str:"您已经断开了此对等设备与服务器的连接，无法再在其上建立任何新连接。"});
                        break;
                    case 'invalid-id':

                        break;
                    case 'network':
                        useLayer({str:"丢失或无法建立与信令服务器的连接。"});
                        break;
                    case 'peer-unavailable'://对等方不存在
                        this.callTip="等待对方进入通话";
                        this.isOpenCallTimer=true;
                        break;
                    case 'ssl-unavailable':
                        useLayer({str:"PeerJS被安全地使用，但云服务器不支持SSL。使用自定义对等服务器。"});
                        break;
                    case 'server-error':
                        useLayer({str:"无法访问服务器。"});
                        break;
                    case 'socket-error':
                        useLayer({str:"来自基础套接字的错误。"});
                        break;
                    case 'socket-closed':
                        useLayer({str:"基础套接字意外关闭。"});
                        break;
                    case 'unavailable-id':
                        useLayer({str:"传递给对等构造函数的ID已被占用。"});
                        break;
                    case 'webrtc':
                        useLayer({str:"本机WebRTC错误。"});
                        break;
                    default:
                        break;
                }
            })

            //peer.disconnect();//关闭与服务器的连接，保留所有现有的数据和媒体连接。peer.disconnected将设置为true，断开连接的事件将触发。
            //peer.reconnect();//尝试使用对等方的旧ID重新连接到服务器。只有断开连接的对等方才能重新连接。无法重新连接被破坏的对等端。如果连接失败（例如，如果现在采用对等方的旧ID），则对等方的现有连接将不会关闭，但任何相关的错误事件都将触发。
            //peer.destroy();//关闭与服务器的连接并终止所有现有连接。peer.destroyd将设置为true。

            //mediaConnection.close();//关闭媒体连接


        },
        methods: {
            /**
             * 按钮功能操纵
             * @param {Number} type 功能类型
             */
            btnOperate(type) {
                if(type==this.operate.audio){//操作语音
                    this.isAudio=!this.isAudio;
                }else if(type==this.operate.video){//操作视频
                    this.isVideo=!this.isVideo;
                }else if(type==this.operate.close){//挂断
                    this.peer&&this.peer.destroy();
                }
            },
            /**
             * 获取用户媒体设备
             */
            getUserMedia(){
                let obj={
                    audio:true,
                    video:true
                }
                let success=(mediaStream)=>{
                    //默认禁用视频轨道
                    (!this.isVideo)&&(mediaStream.getVideoTracks()[0].enabled=false);

                    this.localStream=mediaStream;
                    console.dir(this.localStream.getVideoTracks())
                    console.log("已使用本地流绑定我的媒体");
                    this.isShowMediaBtn=true;
                    this.callPeer(this.localStream);
                }
                let err=(e)=>{
                    let name=e.name;
                    if(name!="TypeError"){
                        this.videoTip="无法获取视频";
                    }
                    this.localStream=new MediaStream();
                    this.callPeer(this.localStream);
                }
                if(navigator.mediaDevices.getUserMedia){
                    //最新标准API
                    navigator.mediaDevices.getUserMedia(obj).then(success).catch(err);
                } else if (navigator.webkitGetUserMedia){
                    //webkit内核浏览器
                    navigator.webkitGetUserMedia(obj).then(success).catch(err);
                } else if(navigator.mozGetUserMedia){
                    //Firefox浏览器
                    navagator.mozGetUserMedia(obj).then(success).catch(err);
                } else if (navigator.getUserMedia){
                    //旧版API
                    navigator.getUserMedia(obj).then(success).catch(err);
                }
            },
            /**
             * 呼叫对等方
             */
            callPeer(mediaStream){
                //呼叫方
                let peerId=this.$route.params["peerId"];//获取被呼叫方id
                if(peerId){
                    //呼叫
                    // let mediaConnection = this.peer.call(peerId,this.localStream);
                    let mediaConnection = this.peer.call(peerId,mediaStream);
                    console.log("呼叫对方");
                    //绑定被呼叫方添加流的事件
                    mediaConnection.on('stream',(stream)=>{
                        useLayer({str:"已接通"});
                        this.isOpenCallTimer=false;
                        console.log("被呼叫方添加流");
                        this.peerStream=stream;
                        let videoTrack=stream.getVideoTracks();
                        console.dir(videoTrack)
                        console.log(videoTrack[0].enabled)
                        if(videoTrack.length==0||!videoTrack[0].enabled){
                            this.callTip="对方未开启摄像头";
                        }
                    })
                    mediaConnection.on('close',()=>{
                        console.log("任意对等方关闭媒体连接事件");
                    })
                    mediaConnection.on('error',()=>{
                        console.log("媒体连接错误事件");
                    })
                }
            }
        },
        watch: {
            /**
             * 改变视频状态
             * @param {Boolean} newVal 视频开关
             */
            isVideo(newVal) {
                let arr = this.localStream.getVideoTracks();//获取媒体流中的视频流轨道
                if(newVal){//打开视频
                    if(arr.length==0){//从未开启过视频
                        //获取用户视频
                        // this.getUserMedia({
                        //     video:newVal
                        // },(localStream)=>{

                        // })
                    }else{//之前打开又关闭过
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].enabled=true;//启用轨道，允许渲染媒体源流
                        }
                        useLayer({str:"摄像头已开启"});
                    }
                }else{//关闭视频
                    for (let i = 0; i < arr.length; i++) {
                        arr[i].enabled=false;//禁用轨道，禁止渲染媒体源流
                    }
                    this.videoTip="摄像头未开启";
                    useLayer({str:"摄像头已关闭"});
                }
            },
            /**
             * 改变音频状态
             * @param {Boolean} newVal 音频开关
             */
            isAudio(newVal){
                let arr = this.localStream.getAudioTracks();//获取媒体流中的音频流轨道
                if(newVal){
                    for (let i = 0; i < arr.length; i++) {
                        arr[i].enabled=true;//启用轨道，允许渲染媒体源流
                    }
                    useLayer({str:"麦克风已开启"});
                }else{
                    for (let i = 0; i < arr.length; i++) {
                        arr[i].enabled=false;//禁用轨道，禁止渲染媒体源流
                    }
                    useLayer({str:"麦克风已关闭"});
                }
            },
            isOpenCallTimer(newVal){
                if(newVal){
                    let timer = setInterval(() => {
                        if(!this.isOpenCallTimer){
                            clearInterval(timer);
                            return;
                        }
                        this.callPeer(this.localStream);
                    }, 2000);
                }
            }
        },
    }
</script>