<style scoped>
	.media-min{
		width:14rem;
		height: 10rem;
	}
	.index-1071{
		z-index: 1071;
	}
	.border-end-after-secondary::after{
		border-right-color: #6c757d!important;
	}
	.border-start-after-primary::after{
		border-left-color: #0d6efd!important;
	}
	.text-preline{
		white-space: pre-line;
	}
</style>
<template>
	<div class="container-lg py-sm-2 px-0 px-sm-2 h-100 position-relative">
		<div class="card bg-secondary border-0 h-100 bg-opacity-50">
			<div class="card-header bg-dark bg-gradient text-white d-flex justify-content-between justify-content-sm-end">
				<span class="d-sm-none">{{user.name}}</span>
				<i class="bi bi-x-square-fill" role="button"></i>
			</div>
			<div class="card-body overflow-hidden p-0 p-sm-2 p-lg-3">
				<div class="d-flex h-100">
					<!-- 好友列表 -->
					<div v-if="isServer" :class="{'d-none d-sm-flex':currFriend!=''}" class="card bg-light border-0 me-0 me-sm-2 me-lg-3 h-100 col-12 col-sm-4 col-md-3 rounded-xs-0 rounded-bottom overflow-hidden">
						<div class="card-header bg-dark bg-gradient text-light d-none d-sm-block">
							<i v-if="isShowRecord" class="bi bi-chevron-left me-1" @click="isShowRecord=false" role="button"></i>
							<span>{{user.name}}</span>
						</div>
						<div class="card-body p-0 d-flex flex-column h-0 flex-grow-1">
							<!-- 搜索栏 -->
							<div class="p-2">
								<div class="d-flex border rounded-pill">
									<i class="bi bi-search d-flex align-items-center ps-3 pe-2"></i>
									<input class="form-control form-control-sm shadow-none border-0 rounded-pill bg-light" type="text" v-model="txtSearch" placeholder="搜索">
								</div>
							</div>
							<!-- 好友栏 -->
							<div class="list-group list-group-flush flex-grow-1 overflow-y-scroll">
								<load v-if="isShowFriendLoad"></load>
								<!-- 记录查看模式 -->
								<template v-else-if="isShowRecord&&(!isShowFriendLoad)&&customerArr.length>0">
									<a href="#" v-for="item of customerArr" :key="item['id']" :class="{'active':currCustomer['id']==item['id']}" class="list-group-item list-group-item-action list-group-item-dark small" @click.prevent="currCustomer=item" role="button">
										<span>{{item['name']}}</span>
									</a>
								</template>
								<!-- 常规模式 -->
								<template v-else-if="(!isShowFriendLoad)&&friend.length>0&&!isShowRecord">
									<a href="#" v-for="(item) in friend" :key="item['id']" :class="{'active':currFriend['id']==item['id']}" class="list-group-item list-group-item-action list-group-item-dark px-2" @click.prevent="currFriend=item" aria-current="true">
										<div class="d-flex">
											<i :class="[getTextClassByFriend(item)]" class="bi bi-person-square me-2 fs-1 d-flex flex-column justify-content-center"></i>
											<div class="flex-grow-1 d-flex">
												<div class="flex-grow-1 d-flex flex-column">
													<div class="d-flex justify-content-between">
														<small class="text-nowrap" :class="[getTextClassByFriend(item)]">
															<span class="fw-bold">{{item['name']}}</span>
															<small v-if="isServer" class="text-muted">({{ item['ip'] }})</small>
														</small>
														<small class="text-muted">
															<small>{{getDateByDate(item['date'])}}</small>
														</small>
													</div>
													<div class="d-flex w-100 justify-content-between align-items-center small">
														<div class="w-0 flex-grow-1 small">
															<label class="text-muted lh-1 text-truncate w-100 align-middle" role="button">{{item['msg']}}</label>
														</div>
														<div class="d-flex flex-column justify-content-center small">
															<small v-show="item['unread']>0" class="badge bg-danger rounded-pill me-1">{{item['unread']}}</small>
														</div>
														<i class="bi bi-trash d-flex align-items-center text-danger" @click.prevent.stop="delFriend(item)" title="从会话列表移除"></i>
													</div>
												</div>
											</div>
										</div>
									</a>
								</template>
								<small v-else class="text-muted text-center px-2">居然一个人都没有~</small>
							</div>
							<!-- 工具栏 -->
							<div v-if="!isShowRecord" class="bg-dark bg-gradient d-flex text-light overflow-hidden">
								<i class="bi bi-trash d-flex align-items-center p-2" @click="delFriend()" title="移除所有会话" role="button"></i>
								<i v-if="user.power.indexOf(userPowerConfig.record)!=-1" class="bi bi-file-earmark-text d-flex align-items-center p-2" @click="isShowRecord=true" title="记录查看" role="button"></i>
							</div>
						</div>
					</div>
					<!-- 聊天窗口 -->
					<div class="flex-grow-1 col-12 col-sm-auto">
						<div class="card bg-light border-0 h-100">
							<div class="card-header bg-dark bg-gradient text-light text-center">
								<div class="d-flex">
									<i v-if="isServer" class="bi bi-chevron-left" @click="currFriend=''" role="button"></i>
									<div class="flex-grow-1">
										<label>{{friendName}}</label>
										<label v-if="!(isServer&&currFriend=='')" :class="[isOnline(currFriend['online'])?'text-success':'text-muted']">{{isOnline(currFriend['online'])?'【在线】':'【离线】'}}</label>
										<small v-if="isServer&&isOnline(currFriend['online'])" class="text-secondary">与{{ currFriend['mname'] }}交谈中</small>
									</div>
								</div>
							</div>
							<div class="card-body p-0">
								<div class="d-flex flex-column h-100">
									<!-- 聊天记录 -->
									<div class="d-flex flex-column flex-grow-1 h-0 overflow-y-scroll scroll-none py-2" v-scroll-down>
										<load v-if="isShowLogLoad"></load>
										<template v-else-if="(!isShowLogLoad)&&logList.length>0">
											<template v-for="(item,i) of logList" :key="item">
												<!-- 文本消息展示 -->
												<template v-if="item['type']==msgTypeConfig.text||item['type']==msgTypeConfig.file">
													<!-- 时间 -->
													<div v-if="!(logList[i-1]&&item['date']==logList[i-1]['date'])" class="text-center small my-1">
														<small class="rounded-1 bg-secondary bg-opacity-50 text-white py-1 px-2">{{item['date']}}</small>
													</div>
													<!-- 消息 -->
													<div class="d-flex flex-column">
														<!-- 客服名称 -->
														<small class="px-3 text-secondary " :class="{'text-end':isCurrUser(item['from'])||(item['from']!=currFriend['id']&&isServer&&!isCurrUser(item['from']))}">{{ item['fname'] }}</small>
														<div :class="{'justify-content-end':isCurrUser(item['from'])||(item['from']!=currFriend['id']&&isServer&&!isCurrUser(item['from']))}" class="d-flex mb-2">
															<!-- 头像 -->
															<i :class="{'order-last':isCurrUser(item['from'])||(item['from']!=currFriend['id']&&isServer&&!isCurrUser(item['from']))}" class="bi bi-person-square mx-3 fs-1 d-flex"></i>
															<!-- 内容 -->
															<div :class="[(isCurrUser(item['from'])||(item['from']!=currFriend['id']&&isServer&&!isCurrUser(item['from'])))?'bg-primary bs-popover-start':'bg-secondary bs-popover-end']" class="rounded-1 text-light position-relative popover p-2 d-flex align-items-center text-break">
																<div :class="[(isCurrUser(item['from'])||(item['from']!=currFriend['id']&&isServer&&!isCurrUser(item['from'])))?'border-start-after-primary':'border-end-after-secondary']" class="position-absolute popover-arrow top-0 mt-2"></div>
																<span class="text-preline" v-if="item['type']==msgTypeConfig.text">{{decodeURI(item['msg'])}}</span>
																<img v-else-if="item['type']==msgTypeConfig.file" class="img-fluid small" :src="item['msg']" alt="图片不存在"  role="button" @click="showImg(item['msg'])">
															</div>
															<!-- 加载效果 -->
															<load v-if="item['msgid']" class="order-first justify-content-center mx-2" spinner-class="spinner-border-sm"></load>
														</div>
													</div>
												</template>
												<template v-if="item['type']==msgTypeConfig.system">
													<div class="d-flex justify-content-center mb-2">
														<div class="alert alert-danger alert-dismissible fade show mb-0 py-2 px-3 small">
															<strong>系统提示：</strong> {{ item['msg'] }}
														</div>
													</div>
												</template>
												<template v-if="item['type']==msgTypeConfig.call">
													<div :class="{'justify-content-end':isCurrUser(item['from'])}" class="d-flex my-2">
														<i :class="{'order-last':isCurrUser(item['from'])}" class="bi bi-person-square mx-3 fs-1 d-flex"></i>
														<div :class="[isCurrUser(item['from'])?'bg-primary bs-popover-start':'bg-secondary bs-popover-end']" class="rounded-1 text-light position-relative popover p-2 d-flex align-items-center text-break">
															<div :class="[isCurrUser(item['from'])?'border-start-after-primary':'border-end-after-secondary']" class="position-absolute popover-arrow top-0 mt-2"></div>
															<div class="d-flex flex-column">
																<div class="d-flex px-1 justify-content-between" @click="enterCall(item['from'])" role="button">
																	<i class="bi bi-telephone-fill d-flex justify-content-center flex-column me-2"></i>
																	<span>进入通话<i class="bi bi-chevron-double-right"></i></span>
																</div>
																<div v-if="isServer" class="mt-1 pt-1 px-1 border-top">
																	{{ mediaUrl }}
																	<!-- <span>{{location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"]}}</span> -->
																	<span class="btn-link ms-2" @click="copyMediaUrl" role="button">复制</span>
																</div>
															</div>
														</div>
													</div>
												</template>
											</template>
										</template>
										<div v-else class="d-flex flex-column text-muted h-100 justify-content-center align-items-center">
											<i class="bi bi-file-earmark-text-fill fs-3"></i>
											<span>空空如也</span>
										</div>
									</div>
									<!-- 操作栏 -->
									<div v-if="!isShowRecord" class="d-flex flex-column">
										<!-- 工具条 -->
										<div class="d-flex bg-dark text-light px-3 bg-gradient">
											<i class="bi bi-emoji-smile-fill d-flex p-2" :class="{'disabled':isEditDisabled}" title="表情" role="button"></i>
											<i v-if="isServer" class="bi bi-telephone-fill d-flex p-2" :class="{'disabled':isEditDisabled}" @click="sendMsg(msgTypeConfig.call)" title="通话聊天" role="button"></i>
											<div>
												<input id="sendImg" class="btn-check" @click="isOpenFileChoose($event)" @change="sendImg($event)" type="file" name="">
												<label class="bi bi-image d-flex p-2" for="sendImg" :class="{'disabled':isEditDisabled}" title="图片" role="button"></label>
											</div>
										</div>
										<!-- 输入栏 -->
										<div class="d-flex flex-column">
											<div class="rounded-top">
												<textarea contenteditable="true" id="txtContent" v-focus class="form-control form-control-nofocus border-0 textarea-noresize shadow-none bg-light" :disabled="isEditDisabled" v-model.trim="txtContent" rows="3" @keydown.enter.prevent="sendMsg(msgTypeConfig.text)"></textarea>
											</div>
											<div class="d-flex justify-content-end p-2" :class="{'disabled':isEditDisabled}">
												<div class="dropup me-2">
													<button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="dropdown">
														<i class="bi bi-chat-square-dots"></i>
													</button>
													<div class="dropdown-menu py-0 mb-1 overflow-hidden">
														<ul class="list-group list-group-flush small text-nowrap">
															<li v-for="item of quickMsgConfig" :key="item" class="list-group-item list-group-item-action py-1" @click="sendMsg(msgTypeConfig.text,item)" role="button">{{item}}</li>
														</ul>
													</div>
												</div>
												
												<button type="button" :class="{'disabled':txtContent.trim()==''}" class="btn btn-sm btn-success px-3" :disabled="txtContent.trim()==''" @click="sendMsg(msgTypeConfig.text)">
													<i class="bi bi-send-fill me-1"></i>
													<span class="me-1">发 送</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</div> 
		</div>
		<audio ref="audio">
			<source :src="currAudioSrc" type="audio/mpeg">
			<embed :src="currAudioSrc">
		</audio>
		<div v-if="isShowMediaChat" :class="[isMediaMin?'media-min start-0 top-0 fs-6':'start-0 top-0 fs-3']" class="position-absolute end-0 bottom-0 rounded-3 overflow-hidden index-1071">
			<media-chat class="" :is-hidden-other="isMediaMin" :local-id="user.id" :peer-id="peerId" :ice-servers="iceServers" @close-chat="isShowMediaChat=false" @chat-msg-websocket="chatMsgWebsocket"></media-chat>
			<div class="position-absolute top-0 start-0 text-light fs-3 pt-2 ps-3">
				<i class="bi bi-aspect-ratio d-flex" @click="isMediaMin=!isMediaMin" role="button"></i>
			</div>
		</div>
	</div>
</template>
<script>
import Load from "@/components/Load.vue";
import MediaChat from "@/components/MediaChat.vue";
import { INTERFACE,USER_TYPE,MSG_TYPE,SEND_RESULT,ONLINE_TYPE,MEDIA_TYPE,AUDIO_NAME, LAYER_TYPE,USER_VIP,USER_POWER } from "@/assets/js/const.js";
import { guid,getDate,copy } from "@/assets/js/common.js";
import { useLayer } from '@/assets/js/useLayer';
export default {
	emits: ['msgWebsocket','sendWebsocket','closeWebsocket'],
	components: { Load,MediaChat },
	directives: {
        'scroll-down': {
			mounted(elem) {
				elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
			},
            updated(elem,binding){
				binding.instance.$nextTick(()=>{
					setTimeout(() => {
						elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
					},10);
				})
            }
        }
    },
	data() {
		return {
			//获取配置
			msgTypeConfig:MSG_TYPE,//获取消息类型配置
			quickMsgConfig:history.state.type==USER_TYPE.client?config().msg.client:config().msg.server,//获取快捷消息配置
			userPowerConfig:USER_POWER,//获取用户权限字符串配置
			
			//常规聊天
			chatMsgFn:null,//要追加到socket的处理函数
			user:{
				id:history.state.id,
				name:history.state.name,
				type:history.state.type,
				guid:history.state.guid,
				power:history.state.power,
				imgurl:history.state.imgurl,
			},//当前用户信息
			txtContent: "",//要发送的消息
			friendList:[],//用户列表
			hideFriendList:[],//隐藏的用户列表，用于客服模式下存放上线但没发送消息的客户
			currFriend:"",//当前选中的好友
			isShowFriendLoad:true,//是否显示用户列表加载效果
			isShowLogLoad:false,//是否显示聊天记录加载效果
			currLog:[],//当前聊天记录
			txtSearch:"",//搜索内容
			sendQueue:[],//发送消息队列
			msgType:{
				help:1,//问候
				noserver:2,//暂无客服
			},
			
			//要播放的音效列表
			audioList:{
				[AUDIO_NAME.up]:require("@/assets/media/up.mp3"),
				[AUDIO_NAME.msg]:require("@/assets/media/msg.mp3")
			},
			currAudioSrc:"",//当前音效地址
			
			//记录查看
			isShowRecord:false,//是否显示记录界面
			serverList:[],//客服列表
			customerList:[],//客户列表
			currCustomer:"",//当前选中的记录查看的客户
			customerLog:{},//缓存当前记录查看的客户的记录
			currCustomerLog:[],//当前选中的记录查看的客户的记录

			//媒体通话
			isMediaMin:false,//媒体通话视频是否缩小
			isShowMediaChat:false,//是否显示媒体聊天窗
			peerId:null,//对方id
			currAudioName:"",//当前播放器中的音效名称
			iceServers:"",//从服务器获取的ice服务器配置
		};
	},
	computed: {
		/**
		 * 记录列表
		 */
		logList(){
			return this.isShowRecord?this.currCustomerLog:this.currLog;
		},
		/**
		 * 好友列表
		 */
		friend() {
			return this.txtSearch.trim()==""?this.friendList:this.friendList.filter(v=>v["name"].indexOf(this.txtSearch)!=-1);
		},
		/**
		 * 记录查看的客户列表
		 */
		customerArr(){
			return this.txtSearch.trim()==""?this.customerList:this.customerList.filter(v=>v["name"].indexOf(this.txtSearch)!=-1);
		},
		/**
		 * 编辑区是否禁用
		 */
		isEditDisabled(){
			if(this.user.type==USER_TYPE.server){
				return this.currFriend!=""?false:true;
			}
		},
		/**
		 * 好友名称显示
		 */
		friendName(){
			let name="";
			if(this.user.type==USER_TYPE.client){
				name="客服";
			}else{
				name=this.currFriend['name'];
			}
			return name;
		},
		/**
		 * 媒体聊天地址
		 */
		mediaUrl(){
			return location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"];
		},
		/**
		 * 当前用户是否客服
		 */
		isServer(){
			return this.user.type==USER_TYPE.server?true:false;
		}
	},
	mounted() {

		//发送心跳 
		this.$emit("sendWebsocket",{
			fun:INTERFACE.online,
			id:this.user.id,
			type:this.user.type
		})

		//获取ice服务器配置
		this.$emit("sendWebsocket",{
			fun:INTERFACE.iceservers,
			id:this.user.id
		})

		// 获取用户列表
		this.$emit("sendWebsocket",{
			fun:INTERFACE.getfriend,
			id:this.user.id,
			type:this.user.type,
			guid:this.user.guid
		})

		//绑定消息响应事件
		this.$emit("msgWebsocket",async (data)=>{
			switch (data["fun"]) {
				case INTERFACE.getfriend:
					this.friendList=data["list"];
					this.isShowFriendLoad=false;
					if(this.isServer){//客服模式
						//获取所有客户聊天记录
						this.friendList.forEach(v=>{
							this.$emit("sendWebsocket",{
								fun:INTERFACE.getlog,
								cid:v["id"],
								sid:this.user.id,
								guid:this.user.guid
							})
						})
					}else{//客户模式
						//获取自己同客服的聊天记录
						this.friendList.length>0&&(this.currFriend=this.friendList[0]);//有客服则默认选中第一个客服
						this.$emit("sendWebsocket",{
							fun:INTERFACE.getlog,
							cid:this.user.id,
							guid:this.user.guid
						})
					}
					break;
				case INTERFACE.updown:
					let friend = this.friendList.find(v=>v["id"]==data["id"]);
					//上线
					if(data["type"]==ONLINE_TYPE.on){
						if(!friend){//好友列表中没有则加入好友列表
							friend={
								id:data["id"],
								name:data["name"],
								online:ONLINE_TYPE.on,
								unread:0
							}
							if(!this.isServer){//客户模式
								this.addLog(this.msgType.help);
								this.playAudio(AUDIO_NAME.up);
								this.friendList.push(friend);
								this.currFriend=friend;//当前只有一个好友，自动选中当前上线好友为聊天好友
								(this.currLog.length>0&&this.currLog[0]["type"]==MSG_TYPE.system)&&this.currLog.shift();//有记录并且记录的第一条为系统提示则移除第一条系统提示
							}else if(this.isServer){//客服模式
								//由于刚上线的用户不需要显示，需要等有接收到消息再显示，所以先加入隐藏的用户列表中
								this.hideFriendList.push({...friend,...{//合并两个对象
									ip:data["ip"],
									isvip:data["isvip"],
									matchid:data["matchid"],
									mname:data["mname"]
								}})
							}
						}else{//好友列表中有则修改在线状态
							this.playAudio(AUDIO_NAME.up);
							friend["online"]=ONLINE_TYPE.on;
							friend["matchid"]=data["matchid"];
							friend["mname"]=data["mname"];
							friend["isvip"]=data["isvip"];
						}
					}else if(data["type"]==ONLINE_TYPE.off){//下线
						friend&&(friend["online"]=ONLINE_TYPE.off);//修改下线人状态
						(!this.isServer)&&(this.friendList=[]);//客户模式清空好友列表
					}
					break;
				case INTERFACE.getlog:
					let logArr=[];
					let arr=JSON.parse(JSON.stringify(data["list"]));
					
					if(!this.isServer){//客户模式
						this.friendList.length==0&&this.addLog(this.msgType.noserver);//没有客服则添加无客服提示
					}

					if(arr.length>0){//有记录
						if(this.isServer&&!this.isShowRecord){//客服模式并且非记录显示模式则需要变更好友列表消息
							this.setFriendLastMsg({
								id:data["cid"],
								msg:decodeURI(arr[0]["msg"]),//第一条消息
								msgtype:arr[0]["type"],//第一条消息的类型
								date:arr[0]["date"],//第一条消息时间
							})
							data["cid"]==this.currFriend["id"]&&(logArr=arr.reverse());//获取的记录是与当前聊天窗口好友的记录
						}else{//客户模式
							logArr=arr.reverse();
						}
					}else{//没有记录
						logArr=[];
					}

					if(this.isShowRecord){//查看记录模式
						this.customerLog[data["cid"]]=logArr;
					}else{
						this.currLog=logArr;
					}

					if(!this.isServer){//客户模式
						this.friendList.length>0&&this.addLog(this.msgType.help);//有客服则添加询问语句
					}

					this.isShowLogLoad=false;
					break;
				case INTERFACE.send:
					let sendResult=data["result"];
					if(sendResult==SEND_RESULT.success){
						let log=this.currLog.find(v=>v["msgid"]==data["msgid"]);
						if(log){//如果记录中已有当前发送成功的消息时，说明为重复发送，则删去老消息
							delete log["msgid"];
						}
					}else if(sendResult==SEND_RESULT.error){
						useLayer({str:"发送失败"});
					}else if(sendResult==SEND_RESULT.repeat){
						useLayer({str:"重复发送"});
					}
					break;
				case INTERFACE.msg:
					let msgFriend = this.friendList.find(v=>v["id"]==data["from"]);
					let msg=decodeURI(data["msg"]);
					this.playAudio(AUDIO_NAME.msg);
					if(msgFriend||!this.isServer){//列表中已有的好友，或者客户模式下（其他客服上线没有推送给客户，导致客户模式下找不到）
						//列表消息变更
						msgFriend&&this.setFriendLastMsg({
							id:data["from"],
							msg:msg,
							msgtype:data["type"]
						});
						//接收的消息为当前聊天窗口好友发来的，或者客户模式
						if(data["from"]==this.currFriend["id"]||!this.isServer){
							// 消息加入聊天面板
							this.currLog.push({
								from:data["from"],
								fname:data["fname"],
								to:data["to"],
								tname:data["tname"],
								type:data["type"],
								msg,
								date:getDate()
							})
						}else{//非当前聊天窗口好友
							msgFriend["unread"]++;//累加未读消息
						}
					}else{//列表中没有的好友
						//从隐藏列表中找到并修改属性，然后加入用户列表并从隐藏好友列表中移除
						let friend=this.hideFriendList.find(v=>v["id"]==data["from"]);
						friend["unread"]=1;
						friend["msg"]=msg;
						friend["msgtype"]=data["type"];
						this.setFriendLastMsg(friend);
						this.hideFriendList.splice(this.hideFriendList.findIndex(v=>v["id"]==data["from"]),1);
					}
					break;
				case INTERFACE.online:
					setTimeout(() => {
						//发送心跳
						this.$emit("sendWebsocket",{
							fun:INTERFACE.online,
							id:this.user.id,
							type:this.user.type
						})
					}, 20000);
					break;
				case INTERFACE.iceservers:
					this.iceServers=data.str;
					break;
				case INTERFACE.getcustomer:
					this.customerList=data["list"];
					for (let i = 0; i < data["list"].length; i++) {
						this.$emit("sendWebsocket",{
							fun:INTERFACE.getlog,
							cid:data["list"][i]["id"],
							guid:this.user.guid
						})
					}
					break;
			}
			this.chatMsgFn&&this.chatMsgFn(data);
		})
	},
	unmounted() {
		this.$emit("closeWebsocket");
	},
	methods:{
		/**
		 * 添加记录
		 */
		addLog(msgType,obj={}){
			if(msgType==this.msgType.help){//问候
				obj.from=this.currFriend["id"];
				obj.fname=this.currFriend["name"];
				obj.to=this.user.id;
				obj.tname=this.user.name;
				obj.type=MSG_TYPE.text;
				obj.msg="您好，有什么可以帮到您吗？";
				obj.date=getDate();
			}else if(msgType==this.msgType.noserver){//暂无客服
				obj.type=MSG_TYPE.system;
				obj.msg="抱歉！暂时没有客服在线";
			}
			this.currLog.push(obj);
		},
		/**
		 * 验证是否打开文件选择器
		 */
		isOpenFileChoose(event){
			if(this.currFriend==""&&this.user.type==USER_TYPE.server){//客服模式下
				useLayer({str:"请先选择一个聊天会话！"});
				event.preventDefault();
			}
		},
		/**
		 * 发送图片
		 */
		sendImg(event){
			//文件格式验证
			let file=event.target.files;
			let fileType=file[0].name.split('.')[1];
			if(["jpg","jpeg","png","gif"].indexOf(fileType)==-1){
				useLayer({str:"只支持发送图片！"});
				return;
			}

			//读取文件
			let reader=new FileReader();
			reader.readAsDataURL(file[0]);
			reader.onload=(e)=>{
				let token=config().ws.substring(config().ws.indexOf("?")+1);
				//上传文件
				this.axios({
					method:"post",
					url:this.user.imgurl+"h_media.ashx",
					params:{
						id:guid(),
						token
					},
					data:{
						data:file[0]
					}
				}).then((response)=>{
					event.target.value="";
					this.sendMsg(MSG_TYPE.file,this.user.imgurl+token+"/"+response);
				});
			}
		},
		/**
		 * 显示图片
		 */
		showImg(imgSrc){
			let newWindow = open("");
			newWindow.document.body.innerHTML="<img src=\""+imgSrc+"\" />";
		},
		/**
		 * 根据完整日期时间字符串获取对应应该显示的时间或者日期字符串
		 */
		getDateByDate(dateStr){
			if(dateStr){
				let date=dateStr.split(' ')[0];//日期
				let time=dateStr.split(' ')[1];//时间
				let nowDate=getDate().split(' ')[0];//当前日期
				if(date==nowDate){//日期一致
					return time;//只返回时间
				}else{//日期不一致
					if(date.split('-')[0]==nowDate.split('-')[0]){//年份一致
						return date.substring(date.indexOf('-')+1);//只返回月日
					}
					return date;//返回年月日
				}
			}
			return "";
		},
		chatMsgWebsocket(fn){
			this.chatMsgFn=fn;
		},
		/**
		 * 发送消息
		 * @param {MSG_TYPE} type 消息类型
		 * @param {String} msg 消息
		 */
		sendMsg(type,msg=""){
			if(this.currFriend==""&&this.isServer){//客服模式下
				useLayer({str:"请先选择一个聊天会话！"});
				return;
			}
			if(type==MSG_TYPE.text){
				msg=msg==""?this.txtContent:msg;
				if(msg==""){
					useLayer({
						str:"不能发送空消息"
					});
					return;
				}
				//清空编辑区
				this.txtContent="";
			}
			let obj={
				from:this.user.id,
				fname:this.user.name,
				to:this.currFriend["id"]||"",
				tname:this.currFriend["name"]||"",
				type:type,
				msg:msg,//要发送的内容
				msgid:guid()
			}
			
			//消息加入聊天面板
			this.currLog.push(Object.assign(JSON.parse(JSON.stringify(obj)),{
				date:getDate()
			}))
			
			//客服模式则列表消息变更
			this.isServer&&this.setFriendLastMsg({
				id:this.currFriend["id"],
				msg,
				msgtype:type,
			});

			//消息加入待发送队列
			obj.msg= encodeURI(obj.msg);
			this.sendQueue.push(Object.assign(JSON.parse(JSON.stringify(obj)),{
				fun:INTERFACE.send,
				guid:this.user.guid,
			}))
		},
		/**
		 * 好友列表消息变更
		 * @param {String} id 好友id
		 * @param {MSG_TYPE} type 消息类型
		 * @param {String} msg 消息
		 */
		setFriendLastMsg(friend){
			let f = this.friendList.find(v=>v["id"]==friend.id);
			let msg=friend.msg||"";
			let date=friend.data?friend.data:getDate();
			if(friend.msgtype==MSG_TYPE.call){
				msg="[通话聊天]";
			}else if(friend.msgtype==MSG_TYPE.file){
				msg="[图片]";
			}
			if(f){//用户列表中存在则修改这个用户
				f["msg"]=msg;
				f["msgtype"]=friend.msgtype;
				f["date"]=date;
			}else{//否则加入这个用户
				friend["msg"]=msg;
				friend["date"]=date;
				this.friendList.unshift(friend);
			}
		},
		/**
		 * 进入媒体聊天
		 */
		enterCall(peerId){
			if(!this.isServer){
				// this.$router.resolve('/media/'+this.user.id);
				// open(location.origin+"/#/media/"+this.user.id);
				this.isShowMediaChat=true;
				this.peerId=peerId;
			}else if(this.isServer){
				this.peerId=this.currFriend["id"];
				this.isShowMediaChat=true;
				// open(location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"]);
			}
		},
		/**
		 * 复制媒体聊天地址
		 */
		copyMediaUrl(){
			copy(this.mediaUrl).then((result)=>{
				if(result=="success"){
					useLayer({str:"已复制到剪贴板"});
				}else{
					useLayer({str:"复制失败"});
				}
			});
		},
		/**
		 * 播放音效
		 * @param {AUDIO_NAME} name 音效名称
		 */
		playAudio(name){
			if(name==this.currAudioName){//要播放的音效为当前播放器中的音效
				this.$refs.audio.play();
			}else{
				this.currAudioName=name;
				this.currAudioSrc=this.audioList[name];
			}
		},
		/**
		 * 根据好友对象获取应该显示的文本样式
		 */
		getTextClassByFriend(friend){
			let className="";
			if(friend["isvip"]==USER_VIP.true){//是会员
				className = friend["online"]?"text-danger":"text-danger text-opacity-50";
			}else{
				className=friend["online"]?"":"text-secondary";
			}
			return className;
		},
		/**
		 * 移除指定好友，没指定则移除所有
		 */
		delFriend(friend){
			let str="";
			let obj={
				fun:INTERFACE.delfriend,
				id:this.user.id,
				guid:this.user.guid
			}
			if(friend){//移除单个
				str="是否移除与【"+friend['name']+"】的会话列表？";
				obj.delid=friend["id"];
			}else{//移除全部
				str="是否移除所有会话？";
			}
			useLayer({
				type:LAYER_TYPE.confirm,
				str,
				btn:["是","否"],
				yesFn:()=>{
					this.$emit("sendWebsocket",obj);
					if(friend){//移除单个
						this.hideFriendList.push(this.friendList.find(v=>v["id"]==friend["id"]));//移除之前需要将在线用户移动到隐藏列表中，否则下次接收消息无法获取到用户资料
						this.friendList.splice(this.friendList.findIndex(v=>v["id"]==friend["id"]),1);//从列表中移除好友
					}else{//移除全部
						this.friendList.forEach(v=>v["online"]==ONLINE_TYPE.on&&this.hideFriendList.push(v));//挑出所有在线用户放入隐藏列表中，否则下次接收消息无法获取到用户资料
						this.friendList=[];
					}
					(!friend||friend["id"]==this.currFriend["id"])&&(this.currFriend="");//如果移除的好友是当前聊天窗口的好友或者移除全部，则改变当前聊天好友
				}
			})
		},
		/**
		 * 是否是当前用户
		 */
		isCurrUser(userId){
			return userId==this.user.id;
		},
		/**
		 * 是否在线
		 */
		isOnline(onlineState){
			return onlineState==ONLINE_TYPE.on;
		}
	},
	watch: {
		/**
		 * 改变当前聊天好友
		 */
		currFriend(newVal) {
			this.$nextTick(()=>{
				document.getElementById("txtContent").focus();
			})
			if(newVal!=""){
				let cid,sid="";
				if(!this.isServer){//当前是客户
					cid=this.user.id;
				}else if(this.isServer){//当前是客服

					cid=this.currFriend["id"];
					sid=this.user.id;
					let friend = this.friendList.find(v=>v["id"]==cid);
					this.isShowLogLoad=true;//没有记录缓存的时候才显示加载效果
					friend["unread"]=0;
					this.$emit("sendWebsocket",{
						fun:INTERFACE.getlog,
						cid,
						sid,
						guid:this.user.guid
					})
				}
			}else{//关闭当前聊天窗口
				this.currLog=[];//清空记录
				this.txtContent="";//清空编辑区
			}
		},
		/**
		 * 发送消息队列，可以实现一次发很多消息然后依次被异步加载发送
		 */
		sendQueue:{
			handler(newVal){
				if(newVal.length>0){//队列中存在未发送的消息
					let queue=JSON.parse(JSON.stringify(newVal));//创建副本
					this.sendQueue=[];//清空队列
					for (let i = 0; i < queue.length; i++) {
						this.$emit("sendWebsocket",queue[i]);
					}
				}
			},
			deep:true
		},
		/**
		 * 改变当前音频播放地址
		 * @param {String} newVal 新地址
		 */
		currAudioSrc(newVal){
			this.$nextTick(()=>{
				this.$refs.audio.load();
				this.$refs.audio.play();
			})
		},
		/**
		 * 显示记录界面
		 */
		isShowRecord(newVal){
			if(newVal){
				this.$emit("sendWebsocket",{
					fun:INTERFACE.getcustomer,
					id:this.user.id,
					guid:this.user.guid
				})
			}
		},
		/**
		 * 改变当前被查看记录的用户
		 */
		currCustomer(newVal){
			if(newVal!=""){
				this.currCustomerLog=this.customerLog[newVal["id"]];
			}
		}
	},
}
</script>
