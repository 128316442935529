import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//使用bootstrap框架，安装：npm install bootstrap，官网：https://getbootstrap.com/ https://www.bootcss.com/
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
//使用bootstrap图标，安装：npm i bootstrap-icons，官网：https://icons.getbootstrap.com
import 'bootstrap-icons/font/bootstrap-icons.css'
//使用弹出插件，安装：npm i layer-src，官网：http://layui-doc.pearadmin.com/layer/mobile/
import 'layer-src/dist/mobile/layer';
import "layer-src/dist/mobile/need/layer.css";
//使用axios，安装：npm install --save axios vue-axios，官网：http://www.axios-js.com/zh-cn/docs/vue-axios.html
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.headers.post['Content-Type']="multipart/form-data";
// axios.defaults.baseURL="http://192.168.1.20:8085/h_media.ashx";
axios.interceptors.response.use((res)=>{
    if(res.data.d){
        try {
            return JSON.parse(res.data.d)
        } catch (error) {
            return res.data.d;
        }
    }
    return res.data;
})

let app = createApp(App);

//注册全局获取焦点指令
app.directive('focus',{
    mounted(elem) {
        elem.focus();
    },
})

app.use(router).use(VueAxios, axios).mount('#app')
