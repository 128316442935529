import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import MediaView from '@/views/MediaView.vue'

const routes = [
  {
    path: '/:type?',
    name: 'login',
    component: LoginView,
    props:true
  },
  {
    path: '/home',
    name: 'home',
    params:{type:String},
    component: HomeView
  },
  {
    path:'/media/:localId/:peerId?',//localId必须，peerId可选
    name:'media',
    component:MediaView,
    // props: ["localId","peerId"],
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
