/**
 * 生成GUID
 */
function guid() {
    var str = '';
    var i = 32;
    while (i--) {
        str += Math.floor(Math.random() * 16.0).toString(16);
    }
    return str;
}
//获取当前时间
function getDate() {
    let date = new Date();
    let str=
        date.getFullYear() + "-" +
        (date.getMonth() + 1<10?"0"+(date.getMonth()+1):date.getMonth()+1) + "-" +
        (date.getDate()<10?"0"+date.getDate():date.getDate()) + " " +
        (date.getHours()<10?"0"+date.getHours():date.getHours()) + ":" +
        (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes());// + ":" + date.getSeconds();
    return str;
}
/**
 * 复制到剪切板
 */
async function copy(text){
    if(!navigator.clipboard){//浏览器不支持clipboard
        //使用将废弃的execCommand
        let textarea=document.createElement("textarea");
        textarea.value=window.location.href;
        textarea.style.top="0";
        textarea.style.left="0";
        textarea.style.position="fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            let result = document.execCommand("copy");
            if(result){
                return "success";
            }
        } catch (error) {
            return "error";
        }
        document.body.removeChild(textarea);
    }else{
        navigator.clipboard.writeText(text);
        return "success";
    }
}
export{
    guid,getDate,copy
}