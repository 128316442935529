/**
 * 是否开启调试模式显示输出 
 */
const IS_DEBUG=false;
/**
 * 接口
 */
const INTERFACE={
    login:"login",//登录
    updown:"updown",//用户上下线
    send:"send",//发送消息
    msg:"msg",//接收消息
    getfriend:"getfriend",//获取好友列表
    getlog:"getlog",//获取聊天记录
    online:"online",//心跳
    iceservers:"iceservers",//ice服务器配置
    delfriend:"delfriend",//移除好友
    getserver:"getserver",//获取客服列表
    getcustomer:"getcustomer",//获取客户列表
}
/**
 * 用户类型
 */
const USER_TYPE={
    client:1,//客户
    server:2,//客服
}
/**
 * 用户是否会员
 */
const USER_VIP={
    false:0,//非会员
    true:1,//会员
}
/**
 * 用户权限字符串
 */
const USER_POWER={
    record:1,//查看所有客服聊天记录
}
/**
 * 弹出框类型
 */
const LAYER_TYPE={
    info:0,//提示框
    confirm:1,//确认框
    load:2,//加载层
}
/**
 * 消息类型
 */
const MSG_TYPE={
    text:1,//文本
    call:2,//通话申请
    file:3,//文件
    system:4,//系统消息
}
/**
 * 在线状态
 */
const ONLINE_TYPE={
    off:0,//离线
    on:1,//在线
}
/**
 * 登录结果
 */
const LOGIN_RESULT={
    success:1,//登录成功
    error:2,//用户名或密码错误
}
/**
 * 发送消息结果
 */
const SEND_RESULT={
    success:1,//发送成功
    error:2,//发送失败
    repeat:3,//重复发送
}
/**
 * 媒体通话类型
 */
const MEDIA_TYPE={
    audio:1,
    video:2
}
/**
 * 音效名称
 */
const AUDIO_NAME={
    up:"up",//上线
    msg:"msg",//消息
}
export{
    IS_DEBUG,INTERFACE,USER_TYPE,LOGIN_RESULT,LAYER_TYPE,
    MSG_TYPE,SEND_RESULT,ONLINE_TYPE,MEDIA_TYPE,AUDIO_NAME,
    USER_VIP,USER_POWER
}